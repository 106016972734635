<template>
    <div class="ShowLinks">
        <ul>
            <li v-for="item in linksList" :key="item.key">
                <div class="link-con" @click="goToLink(item)">
                    <img :src="item.blogLogo" alt="" />
                    <div class="info">
                        <p class="blog-name">{{ item.blogName }}</p>
                        <p class="blog-desc">{{ item.blogDesc }}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    export default {
        name: 'ShowLinks',

        data() {
            return {
                linksList: [],
            }
        },

        mounted() {
            this.getFriendLinks()
        },

        methods: {
            ...mapActions({
                vxGetFriendLinks: 'friend/getFriendLinks',
            }),
            async getFriendLinks() {
                try {
                    const data = await this.vxGetFriendLinks()
                    this.linksList = data.map((item) => {
                        return {
                            ...item,
                            key: item._id,
                        }
                    })
                    console.log(data)
                } catch (error) {}
            },
            goToLink(item) {
                let href = item.blogOrigin.includes('http://') ? item.blogOrigin : `http://` + item.blogOrigin
                window.open(href)
            },
        },
    }
</script>

<style lang="less" scoped>
    .ShowLinks {
        padding: 15px;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                margin-bottom: 5px;
                .link-con {
                    display: flex;
                    padding: 15px;
                    width: 377px;
                    height: 71px;
                    justify-content: space-between;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 20%;
                        margin-right: 15px;
                    }
                    .info {
                        flex: 1;
                        .blog-name {
                            font-size: 16px;
                            color: #555;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .blog-desc {
                            font-size: 13px;
                            color: #a0a0a0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    &:hover {
                        background-color: #f6f8f8;
                    }
                }
            }
        }
    }
</style>
