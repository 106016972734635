<template>
    <div class="ApplyLinks">
        <a-form ref="ruleForm" :form="form" @submit="handleSubmit">
            <a-form-item v-bind="formItemLayout" label="博客名称">
                <a-auto-complete v-decorator="['blogName', {rules: [{required: true, message: 'Please input blog name!'}]}]">
                    <a-input />
                </a-auto-complete>
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="博客地址">
                <a-auto-complete
                    v-decorator="['blogOrigin', {rules: [{required: true, message: 'Please input blog website!'}]}]"
                    placeholder="blog website"
                    @change="handleWebsiteChange"
                >
                    <template slot="dataSource">
                        <a-select-option v-for="website in autoCompleteResult" :key="website">
                            {{ website }}
                        </a-select-option>
                    </template>
                    <a-input />
                </a-auto-complete>
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="博客图标">
                <a-auto-complete v-decorator="['blogLogo', {rules: [{required: true, message: 'Please input blog logo!'}]}]">
                    <a-input />
                </a-auto-complete>
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="博客简介">
                <a-auto-complete v-decorator="['blogDesc', {rules: [{required: true, message: 'Please input blog describe!'}]}]">
                    <a-input />
                </a-auto-complete>
            </a-form-item>
            <a-form-item v-bind="tailFormItemLayout">
                <a-button type="primary" html-type="submit"> 提交申请 </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    export default {
        name: 'ApplyLinks',

        data() {
            return {
                autoCompleteResult: [],
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 3},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 16},
                    },
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 24,
                            offset: 0,
                        },
                        sm: {
                            span: 16,
                            offset: 8,
                        },
                    },
                },
            };
        },


        methods: {
            ...mapActions({vxApplyFriendLinks: 'friend/applyFriendLinks'}),
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                        let ruleForm = {...values}
                        const data = await this.vxApplyFriendLinks({ruleForm})
                        console.log(data);
                        if (data.code) {
                            this.$message.success(data.msg)
                            this.form.resetFields()
                        } else {
                            this.$message.error(data.msg)

                        }
                    }
                });
            },


            handleWebsiteChange(value) {
                let autoCompleteResult;
                if (!value) {
                    autoCompleteResult = [];
                } else {
                    autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
                }
                this.autoCompleteResult = autoCompleteResult;
            },
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'register'});
        },
        mounted() {

        },
    };


</script>

<style lang="less" scoped>
    .ApplyLinks {
        padding: 15px;
    }
</style>