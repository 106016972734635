<template>
    <div class="BlogFriend">
        <div class="c-title">
            <h1><span v-html="ARTICLE_ICON.links"></span> 友情链接</h1>
            <p>我的小伙伴们~</p>
        </div>
        <!-- 内容输出部分 -->
        <div class="c-wrap">
            <div class="link-wrap">
                <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" tab="友链展示">
                        <ShowLinks />
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="申请友链" force-render>
                        <ApplyLinks />
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {ARTICLE_ICON} from '@/lib/article_const'
    import ShowLinks from './components/ShowLinks.vue'
    import ApplyLinks from './components/ApplyLinks.vue';
    export default {
        components: {ShowLinks, ApplyLinks},
        name: 'BlogFriend',

        data() {
            return {
                ARTICLE_ICON
            };
        },

        methods: {
            callback(key) {
                console.log(key);
            },
        },
        mounted() {

        },

    };
</script>

<style lang="less" scoped>
    .BlogFriend {
        position: relative;
        min-height: calc(100vh - 50px);
        background-color: #f1f3f4;
        .c-title {
            box-sizing: border-box;
            height: 100px;
            padding: 20px 20px 0;
            background-color: #f9f9f9;
            h1 {
                font-weight: 200;
            }
            p {
                margin-top: 5px;
                letter-spacing: 2px;
                color: #777;
                font-size: 13px;
            }
        }
        .c-wrap {
            padding: 20px;
            /deep/ .link-wrap {
                min-height: 300px;
                border-radius: 4px;
                background-color: #fff;
                .ant-tabs-nav .ant-tabs-tab-active {
                    color: #777;
                }
                .ant-tabs-nav .ant-tabs-tab:hover {
                    color: #202020;
                }
                .ant-tabs-ink-bar {
                    background-color: #202020;
                    height: 1px;
                }
            }
        }
    }
</style>